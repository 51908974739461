import {Box, Button, Flex, Modal, Select, Text, TextInput} from "@mantine/core";
import stepsConfig from "../../pages/calls/stepsConfig";
import { useGetApiStepGetStepTypes } from "../../api/endpoints";
import { CreateStepDto } from "../../api/model";
import React, {forwardRef, ReactNode, useCallback, useEffect, useImperativeHandle, useState} from "react";
import { useNewCallStore } from "../../pages/admin/CreateCall";
import Editor from "../Editor";
import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "@tabler/icons";

const ModalTypeName = ({
  typeName,
  opened,
  setOpened,
}: {
  typeName: string;
  opened: boolean;
  setOpened: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} centered onClose={setOpened} withCloseButton={false}>
      <Text mb={10}>
        {(typeName === "survey" && t("components.forms.popup-edit-survey")) ||
          (typeName === "miniDyi" && t("components.forms.popup-edit-minidyi"))}
      </Text>
    </Modal>
  );
};

const IconTypeName = ({ setOpened }: { setOpened: () => void }) => {
  return (
    <Flex mb={7}>
      <IconInfoCircle
        size={22}
        onClick={setOpened}
        style={{
          cursor: "pointer",
          marginLeft: 10,
          color: "#228be6",
        }}
      />
    </Flex>
  );
};


const CreateStep = function ({ step ,close}: { step: CreateStepDto, close: ()=>void }) {
  const { data: stepTypes, error: errorStepTypes } =
    useGetApiStepGetStepTypes();

  const [selectedStepTypeId, setSelectedStepTypeId] = useState("");
  const [typeName, setTypeName] = useState("");
  const [contentBuffer, setContentBuffer] = useState(step.content ??"");
  const [nameBuffer, setNameBuffer] = useState(step.name ?? "");
  const [typeBuffer, setTypeBuffer] = useState(step.stepTypeId ?? "");
  const [descriptionBuffer, setDescriptionBuffer] = useState(step.description ?? "");
  const { t } = useTranslation();
  const [stepCreationComponent, setStepCreationComponent] =
    useState<ReactNode>(null);

  const { setStepField } = useNewCallStore((store) => store);

  const setData = (v: string) => setContentBuffer(v)

    const save = useCallback(()=>{
        //console.log("save")
        setStepField(step, "content", contentBuffer);
        setStepField(step, "name", nameBuffer);
        setStepField(step, "description", descriptionBuffer);
        setStepField(step, "stepTypeId", typeBuffer);
        close()
    },[contentBuffer, nameBuffer,descriptionBuffer,typeBuffer]);
    
    const cancel = useCallback(()=>{
        setContentBuffer("");
        setNameBuffer("");
        setDescriptionBuffer("");
        setTypeBuffer("");
        close()
    },[]);

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setSelectedStepTypeId(step.stepTypeId!);
  }, []);
  useEffect(() => {
    setSelectedStepTypeId(typeBuffer);
  }, [typeBuffer]);
  useEffect(() => {
    if (stepTypes) {
      setTypeName(stepTypes?.find((st) => st.id == step.stepTypeId)?.name!);
    }
  }, [stepTypes, step.stepTypeId]);
  useEffect(() => {
    if (
      typeName != "" &&
      stepsConfig[typeName] &&
      typeof stepsConfig[typeName].CreationComponent != "undefined"
    ) {
      setStepCreationComponent(
        React.createElement(stepsConfig[typeName].CreationComponent, {
          content: step.content,
          setData,
        })
      );
    }
  }, [typeName]);

  if (errorStepTypes) return <div>{errorStepTypes.message}</div>;
  if (!stepTypes) return <div>{t("components.forms.loading")}</div>;
  if (!step) return <div>{t("components.forms.loading")}</div>;

  return (
    <>
      <ModalTypeName
        typeName={typeName}
        opened={opened}
        setOpened={() => setOpened(false)}
      />
      <Flex align={"end"}>
        <TextInput
          style={{ width: "100%", marginRight: 15 }}
          withAsterisk
          label={t("components.forms.name")}
          value={nameBuffer}
          onChange={(e) => {
            e.stopPropagation();
            setNameBuffer(e.target.value)
          }}
        />
        <Select
          style={{ width: "100%" }}
          label={t("components.forms.step-type")}
          required
          data={stepTypes
            .filter((e) => e.name !== "quiz")
            .map((s) => {
              return {
                label:
                  s.name === "survey"
                    ? t("components.forms.survey")
                    : s.name === "text"
                    ? t("components.forms.text")
                    : s.name === "miniDyi"
                    ? t("components.forms.impact-framework")
                    : s.name === "upload"
                    ? t("components.forms.upload")
                    : s.name!,
                value: s.id!,
              };
            })}
          value={selectedStepTypeId}
          onChange={(v) => {
            setSelectedStepTypeId(v!);
            setTypeName(stepTypes.find((st) => st.id == v)?.name!);
            setTypeBuffer(v!)
          }}
        />
        {(typeName === "survey" || typeName === "miniDyi") && (
          <IconTypeName setOpened={() => setOpened(true)} />
        )}
      </Flex>
      <Box my={20}>
        <Text size={"sm"} mb={4}>
          {t("pages.admin.description-section")}
        </Text>
        <Editor
          content={descriptionBuffer}
          onChange={(value) => {
            setDescriptionBuffer(value)
          }}
        />
      </Box>
      <Text size={"md"} weight={"bold"} mt={4}>
        {t("pages.admin.content")}
      </Text>
      <hr />
      {stepCreationComponent}
        <Flex 
            pos={"sticky"} 
              bottom={0}
            p={12}
            my={12}
              w={"full"}
              justify={"flex-end"}
            bg={"white"}
        >
            <Button color={"red"} variant={"outline"} mx={12} onClick={cancel}>Annulla</Button>
            <Button color={"primary"} onClick={save}>Salva</Button>
        </Flex>
    </>
  );
};

export default CreateStep;
