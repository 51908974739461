import { Input, Space, Text, Title } from "@mantine/core";
import useMiniDyiStore from "./miniDYIStore";
import { useTranslation } from "react-i18next";
function SelectBudget({readOnly}: {readOnly?: boolean}) {
  const setBudget = useMiniDyiStore((state) => state.setBudget);
  const { t } = useTranslation();
  const budget = useMiniDyiStore((state) => state.budget);
  return (
    <>
      <Title order={2}>{t("components.mini-dyi.budget")}</Title>
      <Space h="md" />
      <Text>{t("components.mini-dyi.budget-amount")}</Text>
      <Space h="xl" />
      <Input.Wrapper label={t("components.mini-dyi.budget-currency")}>
        <Input
          onChange={(event: any) => setBudget(event.target.value)}
          value={Number(budget).toString()}
          type="number"
          min="0"
          disabled={readOnly}
        />
      </Input.Wrapper>
    </>
  );
}

export default SelectBudget;
