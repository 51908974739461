import { useLocalStorage } from "@mantine/hooks";
import { AxiosRequestConfig } from "axios/index";
import { useEffect } from "react";
import { mutate } from "swr";
import { AXIOS_INSTANCE } from "../api/axios-instance";
import { getGetApiCallGetCallListUserKey } from "../api/endpoints";

const useSelectedOrganizationId = () => {
  const LsHookReturn = useLocalStorage<string | null>({
    key: "selectedOrganizationId",
    defaultValue: null,
  });
  const selectedOrganizationId = LsHookReturn[0];
  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use(
      (config) =>
        ({
          ...config,
          headers: selectedOrganizationId
            ? {
                ...config.headers,
                SelectedOrganizationId: selectedOrganizationId,
              }
            : config.headers,
        } as AxiosRequestConfig<any>)
    );
    //console.log("mutate getGetApiCallGetCallListUserKey")
    mutate(getGetApiCallGetCallListUserKey());
    
    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [selectedOrganizationId]);

  return LsHookReturn;
};

export default useSelectedOrganizationId;
