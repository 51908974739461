/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Test01
 * OpenAPI spec version: v1
 */
import useSwr from 'swr'
import type {
  SWRConfiguration,
  Key
} from 'swr'
import type {
  CallDto,
  GetApiCallGetCallByIdParams,
  CallResponse,
  CallType,
  CreateCallDto,
  CallDtoBody,
  PostApiCallDeleteCallParams,
  FullAnswerForPDF,
  GetApiCallGetAllAnswerForPdfParams,
  SubmissionResponse,
  CreateSubmissionDtoBody,
  StepResult,
  CallStepResultDto,
  GetApiCallGetSubmissionByIDParams,
  GetApiCallGetSubmissionByIDAdminParams,
  StepResultResponse,
  GetApiCallGetStepResultParams,
  GetApiCallGetStepResultByCallParams,
  CloseCallSubmissionsDTO,
  PostApiCallCloseCallSubmissionParams,
  CreateCallTypeDtoBody,
  CreateCallTypeDto,
  GetApiCallGetCallTypeParams,
  ExtraInfoAllegatoResultForSubmission,
  CallSubmission,
  BodyBody,
  PostApiCallSubmissionReOpenSubmissionParams,
  PostApiCallSubmissionDeleteSubmissionParams,
  AppConfigDTOBody,
  PostApiConfigActivateConfigParams,
  DeleteApiConfigDeleteConfigParams,
  AppConfigDTO,
  ExtraInfoAllegatoResult,
  PostApiFileuploadUploadfiletosubmissionBody,
  GetApiFileUploadGetFileParams,
  Attachment,
  AttachmentType,
  GetApiFileUploadGetFileUrlParams,
  PostApiFileUploadRemoveAttachementParams,
  ImpactFrameworkDTO,
  GetApiImpactFrameworkDeleteImpactFrameworkSetParams,
  GetApiImpactFrameworkGetImpactFrameworkByIdParams,
  SaveStatusDTO,
  MiniDyiReqDTO,
  MiniDyiRespDTO,
  GetApiImpactFrameworkMiniDYIParams,
  SdgDTO,
  OutcomeTypeRequestBody,
  OutcomeRequest,
  StakeholderType,
  GetApiImpactFrameworkGetStakeholderTypeParams,
  IndicatorTypeDTO,
  PostApiJwtTokenExchangeBodyOne,
  PostApiJwtTokenExchangeBodyTwo,
  PostApiJwtTokenExchangeBodyThree,
  CreateOrgDto,
  OrganizationDto,
  GetApiOrganizationGetOrganizationByIdParams,
  PostApiOrganizationDeleteOrganizationParams,
  OutcomeTypeDTO,
  PostApiOutcomeGetOutcomeTypeParams,
  EmbedParams,
  GetApiPBITokensParams,
  GetApiPBITokensGetPBITokenRlsParams,
  PowerBI,
  GetApiPowerBiAvailableParams,
  LogEmailDto,
  SJSurveyType,
  SJSurveyTypeDTO,
  SJSurvey,
  CreateSurveyRequestDto,
  SJSurveyJson,
  CreateSurveyJsonRequestDto,
  PostApiSJSurveyUpdateSurveyJsonParams,
  SJQuestionsExtended,
  PostApiSJSurveyConsolidateQuestionsParams,
  SJSurveySubmission,
  PostApiSJSurveyCloseSurveySubmissionParams,
  PostApiSJSurveyOpenSurveySubmissionParams,
  GetApiSJSurveyGetSurveysParams,
  GetApiSJSurveyGetLastSurveyJSONParams,
  GetApiSJSurveyGetSurveyJsonByIdParams,
  JSONContent,
  GetApiSJSurveyGetSurveyJsonBySubmissionParams,
  GetApiSJSurveyGetSubmissionParams,
  SJSurveyInstance,
  GetApiSJSurveyGetSurveyBySubmissionIDParams,
  GetApiSJSurveyGetSurveyByTypeParams,
  SurveySubmissionRequestDto,
  GetApiSJSurveyGetSurveyJsonBySurveyIDParams,
  Step,
  GetApiStepGetStepByIdParams,
  GetApiStepGetStepsParams,
  StepType,
  CreateStepDtoBody,
  GetApiStepUpdateSJQuestionsExtendedParams,
  GetApiStepUpdateSJQuestionsExtendedAllParams,
  CreateStepTypeDto,
  UserDto
} from '../model'
import { customInstance } from '../axios-instance'
import type { ErrorType } from '../axios-instance'


  
  export const getApiCallGetCallById = (
    params?: GetApiCallGetCallByIdParams,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/GetCallById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetCallByIdKey = (params?: GetApiCallGetCallByIdParams,) => [`/api/Call/GetCallById`, ...(params ? [params]: [])];

    
export type GetApiCallGetCallByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallById>>>
export type GetApiCallGetCallByIdQueryError = ErrorType<unknown>

export const useGetApiCallGetCallById = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetCallByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallByIdKey(params) : null);
  const swrFn = () => getApiCallGetCallById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallListUser = (
    
 ) => {
      return customInstance<CallResponse[]>(
      {url: `/api/Call/GetCallListUser`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallListUserKey = () => [`/api/Call/GetCallListUser`];

    
export type GetApiCallGetCallListUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallListUser>>>
export type GetApiCallGetCallListUserQueryError = ErrorType<unknown>

export const useGetApiCallGetCallListUser = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallListUser>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallListUserKey() : null);
  const swrFn = () => getApiCallGetCallListUser();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallList = (
    
 ) => {
      return customInstance<CallDto[]>(
      {url: `/api/Call/GetCallList`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallListKey = () => [`/api/Call/GetCallList`];

    
export type GetApiCallGetCallListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallList>>>
export type GetApiCallGetCallListQueryError = ErrorType<unknown>

export const useGetApiCallGetCallList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallListKey() : null);
  const swrFn = () => getApiCallGetCallList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetCallTypes = (
    
 ) => {
      return customInstance<CallType[]>(
      {url: `/api/Call/GetCallTypes`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetCallTypesKey = () => [`/api/Call/GetCallTypes`];

    
export type GetApiCallGetCallTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallTypes>>>
export type GetApiCallGetCallTypesQueryError = ErrorType<unknown>

export const useGetApiCallGetCallTypes = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallTypesKey() : null);
  const swrFn = () => getApiCallGetCallTypes();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallCreateCall = (
    createCallDto: CreateCallDto,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/CreateCall`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCallDto
    },
      );
    }
  


export const putApiCallUpdateCall = (
    callDtoBody: CallDtoBody,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/UpdateCall`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: callDtoBody
    },
      );
    }
  


export const putApiCallUpsertCall = (
    callDtoBody: CallDtoBody,
 ) => {
      return customInstance<CallDto>(
      {url: `/api/Call/UpsertCall`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: callDtoBody
    },
      );
    }
  


export const postApiCallDeleteCall = (
    params?: PostApiCallDeleteCallParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Call/DeleteCall`, method: 'post',
        params
    },
      );
    }
  


export const getApiCallGetAllAnswerForPdf = (
    params?: GetApiCallGetAllAnswerForPdfParams,
 ) => {
      return customInstance<FullAnswerForPDF>(
      {url: `/api/Call/GetAllAnswerForPdf`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetAllAnswerForPdfKey = (params?: GetApiCallGetAllAnswerForPdfParams,) => [`/api/Call/GetAllAnswerForPdf`, ...(params ? [params]: [])];

    
export type GetApiCallGetAllAnswerForPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetAllAnswerForPdf>>>
export type GetApiCallGetAllAnswerForPdfQueryError = ErrorType<unknown>

export const useGetApiCallGetAllAnswerForPdf = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetAllAnswerForPdfParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetAllAnswerForPdf>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetAllAnswerForPdfKey(params) : null);
  const swrFn = () => getApiCallGetAllAnswerForPdf(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallUpsertCallSubmission = (
    createSubmissionDtoBody: CreateSubmissionDtoBody,
 ) => {
      return customInstance<SubmissionResponse>(
      {url: `/api/Call/UpsertCallSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubmissionDtoBody
    },
      );
    }
  


export const postApiCallUpsertCallStepResult = (
    callStepResultDto: CallStepResultDto,
 ) => {
      return customInstance<StepResult>(
      {url: `/api/Call/UpsertCallStepResult`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: callStepResultDto
    },
      );
    }
  


export const getApiCallGetSubmissionByID = (
    params?: GetApiCallGetSubmissionByIDParams,
 ) => {
      return customInstance<SubmissionResponse>(
      {url: `/api/Call/GetSubmissionByID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetSubmissionByIDKey = (params?: GetApiCallGetSubmissionByIDParams,) => [`/api/Call/GetSubmissionByID`, ...(params ? [params]: [])];

    
export type GetApiCallGetSubmissionByIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetSubmissionByID>>>
export type GetApiCallGetSubmissionByIDQueryError = ErrorType<unknown>

export const useGetApiCallGetSubmissionByID = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetSubmissionByIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetSubmissionByID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetSubmissionByIDKey(params) : null);
  const swrFn = () => getApiCallGetSubmissionByID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetSubmissionByIDAdmin = (
    params?: GetApiCallGetSubmissionByIDAdminParams,
 ) => {
      return customInstance<SubmissionResponse>(
      {url: `/api/Call/GetSubmissionByIDAdmin`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetSubmissionByIDAdminKey = (params?: GetApiCallGetSubmissionByIDAdminParams,) => [`/api/Call/GetSubmissionByIDAdmin`, ...(params ? [params]: [])];

    
export type GetApiCallGetSubmissionByIDAdminQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetSubmissionByIDAdmin>>>
export type GetApiCallGetSubmissionByIDAdminQueryError = ErrorType<unknown>

export const useGetApiCallGetSubmissionByIDAdmin = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetSubmissionByIDAdminParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetSubmissionByIDAdmin>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetSubmissionByIDAdminKey(params) : null);
  const swrFn = () => getApiCallGetSubmissionByIDAdmin(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetStepResult = (
    params?: GetApiCallGetStepResultParams,
 ) => {
      return customInstance<StepResultResponse>(
      {url: `/api/Call/GetStepResult`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetStepResultKey = (params?: GetApiCallGetStepResultParams,) => [`/api/Call/GetStepResult`, ...(params ? [params]: [])];

    
export type GetApiCallGetStepResultQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetStepResult>>>
export type GetApiCallGetStepResultQueryError = ErrorType<unknown>

export const useGetApiCallGetStepResult = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetStepResultParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetStepResult>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetStepResultKey(params) : null);
  const swrFn = () => getApiCallGetStepResult(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetStepResultByCall = (
    params?: GetApiCallGetStepResultByCallParams,
 ) => {
      return customInstance<StepResultResponse[]>(
      {url: `/api/Call/GetStepResultByCall`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetStepResultByCallKey = (params?: GetApiCallGetStepResultByCallParams,) => [`/api/Call/GetStepResultByCall`, ...(params ? [params]: [])];

    
export type GetApiCallGetStepResultByCallQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetStepResultByCall>>>
export type GetApiCallGetStepResultByCallQueryError = ErrorType<unknown>

export const useGetApiCallGetStepResultByCall = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetStepResultByCallParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetStepResultByCall>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetStepResultByCallKey(params) : null);
  const swrFn = () => getApiCallGetStepResultByCall(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiCallGetSubmissions = (
    
 ) => {
      return customInstance<SubmissionResponse[]>(
      {url: `/api/Call/GetSubmissions`, method: 'get'
    },
      );
    }
  

export const getGetApiCallGetSubmissionsKey = () => [`/api/Call/GetSubmissions`];

    
export type GetApiCallGetSubmissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetSubmissions>>>
export type GetApiCallGetSubmissionsQueryError = ErrorType<unknown>

export const useGetApiCallGetSubmissions = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetSubmissions>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetSubmissionsKey() : null);
  const swrFn = () => getApiCallGetSubmissions();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallCloseCallSubmission = (
    params?: PostApiCallCloseCallSubmissionParams,
 ) => {
      return customInstance<CloseCallSubmissionsDTO[]>(
      {url: `/api/Call/CloseCallSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiCallCreateCallType = (
    createCallTypeDtoBody: CreateCallTypeDtoBody,
 ) => {
      return customInstance<CallType>(
      {url: `/api/Call/CreateCallType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCallTypeDtoBody
    },
      );
    }
  


export const putApiCallUpsertCallType = (
    createCallTypeDtoBody: CreateCallTypeDtoBody,
 ) => {
      return customInstance<CallType>(
      {url: `/api/Call/UpsertCallType`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: createCallTypeDtoBody
    },
      );
    }
  


export const getApiCallGetCallType = (
    params?: GetApiCallGetCallTypeParams,
 ) => {
      return customInstance<CreateCallTypeDto>(
      {url: `/api/Call/GetCallType`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiCallGetCallTypeKey = (params?: GetApiCallGetCallTypeParams,) => [`/api/Call/GetCallType`, ...(params ? [params]: [])];

    
export type GetApiCallGetCallTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiCallGetCallType>>>
export type GetApiCallGetCallTypeQueryError = ErrorType<unknown>

export const useGetApiCallGetCallType = <TError = ErrorType<unknown>>(
 params?: GetApiCallGetCallTypeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiCallGetCallType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiCallGetCallTypeKey(params) : null);
  const swrFn = () => getApiCallGetCallType(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiCallSubmissionAddCustomFile = (
    extraInfoAllegatoResultForSubmission: ExtraInfoAllegatoResultForSubmission,
 ) => {
      return customInstance<void>(
      {url: `/api/CallSubmission/AddCustomFile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: extraInfoAllegatoResultForSubmission
    },
      );
    }
  


export const postApiCallSubmissionCreateSubmission = (
    createSubmissionDtoBody: CreateSubmissionDtoBody,
 ) => {
      return customInstance<CallSubmission>(
      {url: `/api/CallSubmission/CreateSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubmissionDtoBody
    },
      );
    }
  


export const postApiCallSubmissionExportExcelSubmission = (
    bodyBody: BodyBody,
 ) => {
      return customInstance<void>(
      {url: `/api/CallSubmission/ExportExcelSubmission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bodyBody
    },
      );
    }
  


export const postApiCallSubmissionReOpenSubmission = (
    params?: PostApiCallSubmissionReOpenSubmissionParams,
 ) => {
      return customInstance<void>(
      {url: `/api/CallSubmission/ReOpenSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiCallSubmissionDeleteSubmission = (
    params?: PostApiCallSubmissionDeleteSubmissionParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/CallSubmission/DeleteSubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiConfigAddConfig = (
    appConfigDTOBody: AppConfigDTOBody,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Config/AddConfig`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appConfigDTOBody
    },
      );
    }
  


export const postApiConfigActivateConfig = (
    params?: PostApiConfigActivateConfigParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Config/ActivateConfig`, method: 'post',
        params
    },
      );
    }
  


export const deleteApiConfigDeleteConfig = (
    params?: DeleteApiConfigDeleteConfigParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Config/DeleteConfig`, method: 'delete',
        params
    },
      );
    }
  


export const getApiConfigGetConfig = (
    
 ) => {
      return customInstance<AppConfigDTO>(
      {url: `/api/Config/GetConfig`, method: 'get'
    },
      );
    }
  

export const getGetApiConfigGetConfigKey = () => [`/api/Config/GetConfig`];

    
export type GetApiConfigGetConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getApiConfigGetConfig>>>
export type GetApiConfigGetConfigQueryError = ErrorType<unknown>

export const useGetApiConfigGetConfig = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiConfigGetConfig>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiConfigGetConfigKey() : null);
  const swrFn = () => getApiConfigGetConfig();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiConfigGetConfigList = (
    
 ) => {
      return customInstance<AppConfigDTO[]>(
      {url: `/api/Config/GetConfigList`, method: 'get'
    },
      );
    }
  

export const getGetApiConfigGetConfigListKey = () => [`/api/Config/GetConfigList`];

    
export type GetApiConfigGetConfigListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiConfigGetConfigList>>>
export type GetApiConfigGetConfigListQueryError = ErrorType<unknown>

export const useGetApiConfigGetConfigList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiConfigGetConfigList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiConfigGetConfigListKey() : null);
  const swrFn = () => getApiConfigGetConfigList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiConfigUpsertConfig = (
    appConfigDTOBody: AppConfigDTOBody,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Config/UpsertConfig`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appConfigDTOBody
    },
      );
    }
  


export const postApiFileUploadUploadFileToSubmission = (
    postApiFileuploadUploadfiletosubmissionBody: PostApiFileuploadUploadfiletosubmissionBody,
 ) => {const formData = new FormData();
formData.append('File', postApiFileuploadUploadfiletosubmissionBody.File)
formData.append('Nome', postApiFileuploadUploadfiletosubmissionBody.Nome)
formData.append('Descrizione', postApiFileuploadUploadfiletosubmissionBody.Descrizione)
formData.append('TipoFileId', postApiFileuploadUploadfiletosubmissionBody.TipoFileId)

      return customInstance<ExtraInfoAllegatoResult>(
      {url: `/api/FileUpload/UploadFileToSubmission`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const postApiFileUploadUploadFile = (
    postApiFileuploadUploadfiletosubmissionBody: PostApiFileuploadUploadfiletosubmissionBody,
 ) => {const formData = new FormData();
formData.append('File', postApiFileuploadUploadfiletosubmissionBody.File)
formData.append('Nome', postApiFileuploadUploadfiletosubmissionBody.Nome)
formData.append('Descrizione', postApiFileuploadUploadfiletosubmissionBody.Descrizione)
formData.append('TipoFileId', postApiFileuploadUploadfiletosubmissionBody.TipoFileId)

      return customInstance<ExtraInfoAllegatoResult>(
      {url: `/api/FileUpload/UploadFile`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getApiFileUploadGetFile = (
    params?: GetApiFileUploadGetFileParams,
 ) => {
      return customInstance<void>(
      {url: `/api/FileUpload/GetFile`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetFileKey = (params?: GetApiFileUploadGetFileParams,) => [`/api/FileUpload/GetFile`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetFileQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetFile>>>
export type GetApiFileUploadGetFileQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetFile = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetFileParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetFile>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetFileKey(params) : null);
  const swrFn = () => getApiFileUploadGetFile(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetAttachments = (
    
 ) => {
      return customInstance<Attachment[]>(
      {url: `/api/FileUpload/GetAttachments`, method: 'get'
    },
      );
    }
  

export const getGetApiFileUploadGetAttachmentsKey = () => [`/api/FileUpload/GetAttachments`];

    
export type GetApiFileUploadGetAttachmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetAttachments>>>
export type GetApiFileUploadGetAttachmentsQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetAttachments = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetAttachments>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetAttachmentsKey() : null);
  const swrFn = () => getApiFileUploadGetAttachments();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetAttachmentTypes = (
    
 ) => {
      return customInstance<AttachmentType[]>(
      {url: `/api/FileUpload/GetAttachmentTypes`, method: 'get'
    },
      );
    }
  

export const getGetApiFileUploadGetAttachmentTypesKey = () => [`/api/FileUpload/GetAttachmentTypes`];

    
export type GetApiFileUploadGetAttachmentTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentTypes>>>
export type GetApiFileUploadGetAttachmentTypesQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetAttachmentTypes = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetAttachmentTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetAttachmentTypesKey() : null);
  const swrFn = () => getApiFileUploadGetAttachmentTypes();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiFileUploadGetFileUrl = (
    params?: GetApiFileUploadGetFileUrlParams,
 ) => {
      return customInstance<string>(
      {url: `/api/FileUpload/GetFileUrl`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiFileUploadGetFileUrlKey = (params?: GetApiFileUploadGetFileUrlParams,) => [`/api/FileUpload/GetFileUrl`, ...(params ? [params]: [])];

    
export type GetApiFileUploadGetFileUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getApiFileUploadGetFileUrl>>>
export type GetApiFileUploadGetFileUrlQueryError = ErrorType<unknown>

export const useGetApiFileUploadGetFileUrl = <TError = ErrorType<unknown>>(
 params?: GetApiFileUploadGetFileUrlParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiFileUploadGetFileUrl>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiFileUploadGetFileUrlKey(params) : null);
  const swrFn = () => getApiFileUploadGetFileUrl(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiFileUploadRemoveAttachement = (
    params?: PostApiFileUploadRemoveAttachementParams,
 ) => {
      return customInstance<void>(
      {url: `/api/FileUpload/RemoveAttachement`, method: 'post',
        params
    },
      );
    }
  


export const getApiImpactFrameworkGetImpactFrameworkSetList = (
    
 ) => {
      return customInstance<ImpactFrameworkDTO[]>(
      {url: `/api/ImpactFramework/GetImpactFrameworkSetList`, method: 'get'
    },
      );
    }
  

export const getGetApiImpactFrameworkGetImpactFrameworkSetListKey = () => [`/api/ImpactFramework/GetImpactFrameworkSetList`];

    
export type GetApiImpactFrameworkGetImpactFrameworkSetListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetImpactFrameworkSetList>>>
export type GetApiImpactFrameworkGetImpactFrameworkSetListQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetImpactFrameworkSetList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetImpactFrameworkSetList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetImpactFrameworkSetListKey() : null);
  const swrFn = () => getApiImpactFrameworkGetImpactFrameworkSetList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiImpactFrameworkDeleteImpactFrameworkSet = (
    params?: GetApiImpactFrameworkDeleteImpactFrameworkSetParams,
 ) => {
      return customInstance<number>(
      {url: `/api/ImpactFramework/DeleteImpactFrameworkSet`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiImpactFrameworkDeleteImpactFrameworkSetKey = (params?: GetApiImpactFrameworkDeleteImpactFrameworkSetParams,) => [`/api/ImpactFramework/DeleteImpactFrameworkSet`, ...(params ? [params]: [])];

    
export type GetApiImpactFrameworkDeleteImpactFrameworkSetQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkDeleteImpactFrameworkSet>>>
export type GetApiImpactFrameworkDeleteImpactFrameworkSetQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkDeleteImpactFrameworkSet = <TError = ErrorType<unknown>>(
 params?: GetApiImpactFrameworkDeleteImpactFrameworkSetParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkDeleteImpactFrameworkSet>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkDeleteImpactFrameworkSetKey(params) : null);
  const swrFn = () => getApiImpactFrameworkDeleteImpactFrameworkSet(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiImpactFrameworkGetImpactFrameworkById = (
    params?: GetApiImpactFrameworkGetImpactFrameworkByIdParams,
 ) => {
      return customInstance<ImpactFrameworkDTO>(
      {url: `/api/ImpactFramework/GetImpactFrameworkById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiImpactFrameworkGetImpactFrameworkByIdKey = (params?: GetApiImpactFrameworkGetImpactFrameworkByIdParams,) => [`/api/ImpactFramework/GetImpactFrameworkById`, ...(params ? [params]: [])];

    
export type GetApiImpactFrameworkGetImpactFrameworkByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetImpactFrameworkById>>>
export type GetApiImpactFrameworkGetImpactFrameworkByIdQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetImpactFrameworkById = <TError = ErrorType<unknown>>(
 params?: GetApiImpactFrameworkGetImpactFrameworkByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetImpactFrameworkById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetImpactFrameworkByIdKey(params) : null);
  const swrFn = () => getApiImpactFrameworkGetImpactFrameworkById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const putApiImpactFrameworkAddImpactFrameworkSet = (
    impactFrameworkDTO: ImpactFrameworkDTO,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/ImpactFramework/AddImpactFrameworkSet`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: impactFrameworkDTO
    },
      );
    }
  


export const putApiImpactFrameworkPutMiniDYI = (
    miniDyiReqDTO: MiniDyiReqDTO,
 ) => {
      return customInstance<SaveStatusDTO>(
      {url: `/api/ImpactFramework/PutMiniDYI`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: miniDyiReqDTO
    },
      );
    }
  


export const getApiImpactFrameworkMiniDYI = (
    params?: GetApiImpactFrameworkMiniDYIParams,
 ) => {
      return customInstance<MiniDyiRespDTO>(
      {url: `/api/ImpactFramework/MiniDYI`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiImpactFrameworkMiniDYIKey = (params?: GetApiImpactFrameworkMiniDYIParams,) => [`/api/ImpactFramework/MiniDYI`, ...(params ? [params]: [])];

    
export type GetApiImpactFrameworkMiniDYIQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkMiniDYI>>>
export type GetApiImpactFrameworkMiniDYIQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkMiniDYI = <TError = ErrorType<unknown>>(
 params?: GetApiImpactFrameworkMiniDYIParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkMiniDYI>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkMiniDYIKey(params) : null);
  const swrFn = () => getApiImpactFrameworkMiniDYI(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiImpactFrameworkGetSdg = (
    
 ) => {
      return customInstance<SdgDTO[]>(
      {url: `/api/ImpactFramework/GetSdg`, method: 'get'
    },
      );
    }
  

export const getGetApiImpactFrameworkGetSdgKey = () => [`/api/ImpactFramework/GetSdg`];

    
export type GetApiImpactFrameworkGetSdgQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetSdg>>>
export type GetApiImpactFrameworkGetSdgQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetSdg = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetSdg>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetSdgKey() : null);
  const swrFn = () => getApiImpactFrameworkGetSdg();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiImpactFrameworkAddOutcomeType = (
    outcomeTypeRequestBody: OutcomeTypeRequestBody,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/AddOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeTypeRequestBody
    },
      );
    }
  


export const postApiImpactFrameworkEditOutcomeType = (
    outcomeTypeRequestBody: OutcomeTypeRequestBody,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/EditOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeTypeRequestBody
    },
      );
    }
  


export const postApiImpactFrameworkEditOutcome = (
    outcomeRequest: OutcomeRequest,
 ) => {
      return customInstance<void>(
      {url: `/api/ImpactFramework/EditOutcome`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: outcomeRequest
    },
      );
    }
  


export const getApiImpactFrameworkGetStakeholderType = (
    params?: GetApiImpactFrameworkGetStakeholderTypeParams,
 ) => {
      return customInstance<StakeholderType[]>(
      {url: `/api/ImpactFramework/GetStakeholderType`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiImpactFrameworkGetStakeholderTypeKey = (params?: GetApiImpactFrameworkGetStakeholderTypeParams,) => [`/api/ImpactFramework/GetStakeholderType`, ...(params ? [params]: [])];

    
export type GetApiImpactFrameworkGetStakeholderTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiImpactFrameworkGetStakeholderType>>>
export type GetApiImpactFrameworkGetStakeholderTypeQueryError = ErrorType<unknown>

export const useGetApiImpactFrameworkGetStakeholderType = <TError = ErrorType<unknown>>(
 params?: GetApiImpactFrameworkGetStakeholderTypeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiImpactFrameworkGetStakeholderType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiImpactFrameworkGetStakeholderTypeKey(params) : null);
  const swrFn = () => getApiImpactFrameworkGetStakeholderType(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiIndicatorGetIndicatorType = (
    bodyBody: BodyBody,
 ) => {
      return customInstance<IndicatorTypeDTO[][]>(
      {url: `/api/Indicator/GetIndicatorType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bodyBody
    },
      );
    }
  


export const postApiJwtTokenExchange = (
    postApiJwtTokenExchangeBody: PostApiJwtTokenExchangeBodyOne | PostApiJwtTokenExchangeBodyTwo | PostApiJwtTokenExchangeBodyThree,
 ) => {
      return customInstance<string>(
      {url: `/api/Jwt/token-exchange`, method: 'post',
      data: postApiJwtTokenExchangeBody
    },
      );
    }
  


export const postApiOrganizationCreateOrganization = (
    createOrgDto: CreateOrgDto,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Organization/CreateOrganization`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createOrgDto
    },
      );
    }
  


export const getApiOrganizationGetOrganizations = (
    
 ) => {
      return customInstance<OrganizationDto[]>(
      {url: `/api/Organization/GetOrganizations`, method: 'get'
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationsKey = () => [`/api/Organization/GetOrganizations`];

    
export type GetApiOrganizationGetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganizations>>>
export type GetApiOrganizationGetOrganizationsQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganizations = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganizations>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationsKey() : null);
  const swrFn = () => getApiOrganizationGetOrganizations();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiOrganizationGetOrganizationById = (
    params?: GetApiOrganizationGetOrganizationByIdParams,
 ) => {
      return customInstance<OrganizationDto>(
      {url: `/api/Organization/GetOrganizationById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiOrganizationGetOrganizationByIdKey = (params?: GetApiOrganizationGetOrganizationByIdParams,) => [`/api/Organization/GetOrganizationById`, ...(params ? [params]: [])];

    
export type GetApiOrganizationGetOrganizationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationById>>>
export type GetApiOrganizationGetOrganizationByIdQueryError = ErrorType<unknown>

export const useGetApiOrganizationGetOrganizationById = <TError = ErrorType<unknown>>(
 params?: GetApiOrganizationGetOrganizationByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiOrganizationGetOrganizationById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiOrganizationGetOrganizationByIdKey(params) : null);
  const swrFn = () => getApiOrganizationGetOrganizationById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const putApiOrganizationUpdateOrganization = (
    organizationDto: OrganizationDto,
 ) => {
      return customInstance<OrganizationDto[]>(
      {url: `/api/Organization/UpdateOrganization`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: organizationDto
    },
      );
    }
  


export const postApiOrganizationDeleteOrganization = (
    params?: PostApiOrganizationDeleteOrganizationParams,
 ) => {
      return customInstance<boolean>(
      {url: `/api/Organization/DeleteOrganization`, method: 'post',
        params
    },
      );
    }
  


export const postApiOutcomeGetOutcomeType = (
    postApiOutcomeGetOutcomeTypeBody: number[],
    params?: PostApiOutcomeGetOutcomeTypeParams,
 ) => {
      return customInstance<OutcomeTypeDTO[][]>(
      {url: `/api/Outcome/GetOutcomeType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postApiOutcomeGetOutcomeTypeBody,
        params
    },
      );
    }
  


export const getApiPBITokens = (
    params?: GetApiPBITokensParams,
 ) => {
      return customInstance<EmbedParams>(
      {url: `/api/PBITokens`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPBITokensKey = (params?: GetApiPBITokensParams,) => [`/api/PBITokens`, ...(params ? [params]: [])];

    
export type GetApiPBITokensQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPBITokens>>>
export type GetApiPBITokensQueryError = ErrorType<unknown>

export const useGetApiPBITokens = <TError = ErrorType<unknown>>(
 params?: GetApiPBITokensParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPBITokens>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPBITokensKey(params) : null);
  const swrFn = () => getApiPBITokens(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPBITokensGetPBITokenRls = (
    params?: GetApiPBITokensGetPBITokenRlsParams,
 ) => {
      return customInstance<EmbedParams>(
      {url: `/api/PBITokens/GetPBITokenRls`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPBITokensGetPBITokenRlsKey = (params?: GetApiPBITokensGetPBITokenRlsParams,) => [`/api/PBITokens/GetPBITokenRls`, ...(params ? [params]: [])];

    
export type GetApiPBITokensGetPBITokenRlsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPBITokensGetPBITokenRls>>>
export type GetApiPBITokensGetPBITokenRlsQueryError = ErrorType<unknown>

export const useGetApiPBITokensGetPBITokenRls = <TError = ErrorType<unknown>>(
 params?: GetApiPBITokensGetPBITokenRlsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPBITokensGetPBITokenRls>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPBITokensGetPBITokenRlsKey(params) : null);
  const swrFn = () => getApiPBITokensGetPBITokenRls(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiPowerBiAvailable = (
    params?: GetApiPowerBiAvailableParams,
 ) => {
      return customInstance<PowerBI[]>(
      {url: `/api/PowerBi/Available`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiPowerBiAvailableKey = (params?: GetApiPowerBiAvailableParams,) => [`/api/PowerBi/Available`, ...(params ? [params]: [])];

    
export type GetApiPowerBiAvailableQueryResult = NonNullable<Awaited<ReturnType<typeof getApiPowerBiAvailable>>>
export type GetApiPowerBiAvailableQueryError = ErrorType<unknown>

export const useGetApiPowerBiAvailable = <TError = ErrorType<unknown>>(
 params?: GetApiPowerBiAvailableParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiPowerBiAvailable>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiPowerBiAvailableKey(params) : null);
  const swrFn = () => getApiPowerBiAvailable(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiSendEmailSendEmail = (
    logEmailDto: LogEmailDto,
 ) => {
      return customInstance<boolean>(
      {url: `/api/SendEmail/SendEmail`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: logEmailDto
    },
      );
    }
  


export const postApiSJSurveyCreateSurveyType = (
    sJSurveyTypeDTO: SJSurveyTypeDTO,
 ) => {
      return customInstance<SJSurveyType>(
      {url: `/api/SJSurvey/CreateSurveyType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurveyTypeDTO
    },
      );
    }
  


export const postApiSJSurveyCreateSurvey = (
    createSurveyRequestDto: CreateSurveyRequestDto,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/CreateSurvey`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSurveyRequestDto
    },
      );
    }
  


export const postApiSJSurveyCreateSurveyJson = (
    createSurveyJsonRequestDto: CreateSurveyJsonRequestDto,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/CreateSurveyJson`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSurveyJsonRequestDto
    },
      );
    }
  


export const postApiSJSurveyUpdateSurveyJson = (
    sJSurveyJson: SJSurveyJson,
    params?: PostApiSJSurveyUpdateSurveyJsonParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/UpdateSurveyJson`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurveyJson,
        params
    },
      );
    }
  


export const postApiSJSurveySaveOrUpdateSJSurvey = (
    sJSurvey: SJSurvey,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/SaveOrUpdateSJSurvey`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJSurvey
    },
      );
    }
  


export const postApiSJSurveyConsolidateQuestions = (
    sJQuestionsExtended: SJQuestionsExtended[],
    params?: PostApiSJSurveyConsolidateQuestionsParams,
 ) => {
      return customInstance<void>(
      {url: `/api/SJSurvey/ConsolidateQuestions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sJQuestionsExtended,
        params
    },
      );
    }
  


export const postApiSJSurveyCloseSurveySubmission = (
    params?: PostApiSJSurveyCloseSurveySubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/CloseSurveySubmission`, method: 'post',
        params
    },
      );
    }
  


export const postApiSJSurveyOpenSurveySubmission = (
    params?: PostApiSJSurveyOpenSurveySubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/OpenSurveySubmission`, method: 'post',
        params
    },
      );
    }
  


export const getApiSJSurveyGetSurveys = (
    bodyBody: BodyBody,
    params?: GetApiSJSurveyGetSurveysParams,
 ) => {
      return customInstance<SJSurvey[]>(
      {url: `/api/SJSurvey/GetSurveys`, method: 'get',
      headers: {'Content-Type': 'application/json', },
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveysKey = (bodyBody: BodyBody,
    params?: GetApiSJSurveyGetSurveysParams,) => [`/api/SJSurvey/GetSurveys`, ...(params ? [params]: []), bodyBody];

    
export type GetApiSJSurveyGetSurveysQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveys>>>
export type GetApiSJSurveyGetSurveysQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveys = <TError = ErrorType<unknown>>(
 bodyBody: BodyBody,
    params?: GetApiSJSurveyGetSurveysParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveys>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveysKey(bodyBody,params) : null);
  const swrFn = () => getApiSJSurveyGetSurveys(bodyBody,params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetLastSurveyJSON = (
    params?: GetApiSJSurveyGetLastSurveyJSONParams,
 ) => {
      return customInstance<string>(
      {url: `/api/SJSurvey/GetLastSurveyJSON`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetLastSurveyJSONKey = (params?: GetApiSJSurveyGetLastSurveyJSONParams,) => [`/api/SJSurvey/GetLastSurveyJSON`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetLastSurveyJSONQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetLastSurveyJSON>>>
export type GetApiSJSurveyGetLastSurveyJSONQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetLastSurveyJSON = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetLastSurveyJSONParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetLastSurveyJSON>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetLastSurveyJSONKey(params) : null);
  const swrFn = () => getApiSJSurveyGetLastSurveyJSON(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonById = (
    params?: GetApiSJSurveyGetSurveyJsonByIdParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/GetSurveyJsonById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonByIdKey = (params?: GetApiSJSurveyGetSurveyJsonByIdParams,) => [`/api/SJSurvey/GetSurveyJsonById`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonById>>>
export type GetApiSJSurveyGetSurveyJsonByIdQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonById = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonByIdKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonBySubmission = (
    params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams,
 ) => {
      return customInstance<JSONContent>(
      {url: `/api/SJSurvey/GetSurveyJsonBySubmission`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonBySubmissionKey = (params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams,) => [`/api/SJSurvey/GetSurveyJsonBySubmission`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonBySubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySubmission>>>
export type GetApiSJSurveyGetSurveyJsonBySubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonBySubmission = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonBySubmissionParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonBySubmissionKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonBySubmission(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSubmission = (
    params?: GetApiSJSurveyGetSubmissionParams,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/GetSubmission`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSubmissionKey = (params?: GetApiSJSurveyGetSubmissionParams,) => [`/api/SJSurvey/GetSubmission`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSubmission>>>
export type GetApiSJSurveyGetSubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSubmission = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSubmissionParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSubmissionKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSubmission(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyBySubmissionID = (
    params?: GetApiSJSurveyGetSurveyBySubmissionIDParams,
 ) => {
      return customInstance<SJSurveyInstance>(
      {url: `/api/SJSurvey/GetSurveyBySubmissionID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyBySubmissionIDKey = (params?: GetApiSJSurveyGetSurveyBySubmissionIDParams,) => [`/api/SJSurvey/GetSurveyBySubmissionID`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyBySubmissionIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyBySubmissionID>>>
export type GetApiSJSurveyGetSurveyBySubmissionIDQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyBySubmissionID = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyBySubmissionIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyBySubmissionID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyBySubmissionIDKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyBySubmissionID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyTypeList = (
    
 ) => {
      return customInstance<SJSurveyType[]>(
      {url: `/api/SJSurvey/GetSurveyTypeList`, method: 'get'
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyTypeListKey = () => [`/api/SJSurvey/GetSurveyTypeList`];

    
export type GetApiSJSurveyGetSurveyTypeListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyTypeList>>>
export type GetApiSJSurveyGetSurveyTypeListQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyTypeList = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyTypeList>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyTypeListKey() : null);
  const swrFn = () => getApiSJSurveyGetSurveyTypeList();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyByType = (
    params?: GetApiSJSurveyGetSurveyByTypeParams,
 ) => {
      return customInstance<SJSurvey>(
      {url: `/api/SJSurvey/GetSurveyByType`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyByTypeKey = (params?: GetApiSJSurveyGetSurveyByTypeParams,) => [`/api/SJSurvey/GetSurveyByType`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyByType>>>
export type GetApiSJSurveyGetSurveyByTypeQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyByType = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyByTypeParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyByType>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyByTypeKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyByType(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveySubmission = (
    surveySubmissionRequestDto: SurveySubmissionRequestDto,
 ) => {
      return customInstance<SJSurveySubmission>(
      {url: `/api/SJSurvey/GetSurveySubmission`, method: 'get',
      headers: {'Content-Type': 'application/json', }
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveySubmissionKey = (surveySubmissionRequestDto: SurveySubmissionRequestDto,) => [`/api/SJSurvey/GetSurveySubmission`, surveySubmissionRequestDto];

    
export type GetApiSJSurveyGetSurveySubmissionQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmission>>>
export type GetApiSJSurveyGetSurveySubmissionQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveySubmission = <TError = ErrorType<unknown>>(
 surveySubmissionRequestDto: SurveySubmissionRequestDto, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveySubmission>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveySubmissionKey(surveySubmissionRequestDto) : null);
  const swrFn = () => getApiSJSurveyGetSurveySubmission(surveySubmissionRequestDto, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiSJSurveyGetSurveyJsonBySurveyID = (
    params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams,
 ) => {
      return customInstance<SJSurveyJson>(
      {url: `/api/SJSurvey/GetSurveyJsonBySurveyID`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiSJSurveyGetSurveyJsonBySurveyIDKey = (params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams,) => [`/api/SJSurvey/GetSurveyJsonBySurveyID`, ...(params ? [params]: [])];

    
export type GetApiSJSurveyGetSurveyJsonBySurveyIDQueryResult = NonNullable<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySurveyID>>>
export type GetApiSJSurveyGetSurveyJsonBySurveyIDQueryError = ErrorType<unknown>

export const useGetApiSJSurveyGetSurveyJsonBySurveyID = <TError = ErrorType<unknown>>(
 params?: GetApiSJSurveyGetSurveyJsonBySurveyIDParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiSJSurveyGetSurveyJsonBySurveyID>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiSJSurveyGetSurveyJsonBySurveyIDKey(params) : null);
  const swrFn = () => getApiSJSurveyGetSurveyJsonBySurveyID(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetStepById = (
    params?: GetApiStepGetStepByIdParams,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/GetStepById`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepGetStepByIdKey = (params?: GetApiStepGetStepByIdParams,) => [`/api/Step/GetStepById`, ...(params ? [params]: [])];

    
export type GetApiStepGetStepByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetStepById>>>
export type GetApiStepGetStepByIdQueryError = ErrorType<unknown>

export const useGetApiStepGetStepById = <TError = ErrorType<unknown>>(
 params?: GetApiStepGetStepByIdParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetStepById>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepByIdKey(params) : null);
  const swrFn = () => getApiStepGetStepById(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetSteps = (
    params?: GetApiStepGetStepsParams,
 ) => {
      return customInstance<Step[]>(
      {url: `/api/Step/GetSteps`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepGetStepsKey = (params?: GetApiStepGetStepsParams,) => [`/api/Step/GetSteps`, ...(params ? [params]: [])];

    
export type GetApiStepGetStepsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetSteps>>>
export type GetApiStepGetStepsQueryError = ErrorType<unknown>

export const useGetApiStepGetSteps = <TError = ErrorType<unknown>>(
 params?: GetApiStepGetStepsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetSteps>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepsKey(params) : null);
  const swrFn = () => getApiStepGetSteps(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepGetStepTypes = (
    
 ) => {
      return customInstance<StepType[]>(
      {url: `/api/Step/GetStepTypes`, method: 'get'
    },
      );
    }
  

export const getGetApiStepGetStepTypesKey = () => [`/api/Step/GetStepTypes`];

    
export type GetApiStepGetStepTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepGetStepTypes>>>
export type GetApiStepGetStepTypesQueryError = ErrorType<unknown>

export const useGetApiStepGetStepTypes = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepGetStepTypes>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepGetStepTypesKey() : null);
  const swrFn = () => getApiStepGetStepTypes();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiStepCreateStep = (
    createStepDtoBody: CreateStepDtoBody,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/CreateStep`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStepDtoBody
    },
      );
    }
  


export const putApiStepUpsertStep = (
    createStepDtoBody: CreateStepDtoBody,
 ) => {
      return customInstance<Step>(
      {url: `/api/Step/UpsertStep`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: createStepDtoBody
    },
      );
    }
  


export const getApiStepUpdateSJQuestionsExtended = (
    params?: GetApiStepUpdateSJQuestionsExtendedParams,
 ) => {
      return customInstance<void>(
      {url: `/api/Step/UpdateSJQuestionsExtended`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepUpdateSJQuestionsExtendedKey = (params?: GetApiStepUpdateSJQuestionsExtendedParams,) => [`/api/Step/UpdateSJQuestionsExtended`, ...(params ? [params]: [])];

    
export type GetApiStepUpdateSJQuestionsExtendedQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtended>>>
export type GetApiStepUpdateSJQuestionsExtendedQueryError = ErrorType<unknown>

export const useGetApiStepUpdateSJQuestionsExtended = <TError = ErrorType<unknown>>(
 params?: GetApiStepUpdateSJQuestionsExtendedParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtended>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepUpdateSJQuestionsExtendedKey(params) : null);
  const swrFn = () => getApiStepUpdateSJQuestionsExtended(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const getApiStepUpdateSJQuestionsExtendedAll = (
    params?: GetApiStepUpdateSJQuestionsExtendedAllParams,
 ) => {
      return customInstance<void>(
      {url: `/api/Step/UpdateSJQuestionsExtendedAll`, method: 'get',
        params
    },
      );
    }
  

export const getGetApiStepUpdateSJQuestionsExtendedAllKey = (params?: GetApiStepUpdateSJQuestionsExtendedAllParams,) => [`/api/Step/UpdateSJQuestionsExtendedAll`, ...(params ? [params]: [])];

    
export type GetApiStepUpdateSJQuestionsExtendedAllQueryResult = NonNullable<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtendedAll>>>
export type GetApiStepUpdateSJQuestionsExtendedAllQueryError = ErrorType<unknown>

export const useGetApiStepUpdateSJQuestionsExtendedAll = <TError = ErrorType<unknown>>(
 params?: GetApiStepUpdateSJQuestionsExtendedAllParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiStepUpdateSJQuestionsExtendedAll>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiStepUpdateSJQuestionsExtendedAllKey(params) : null);
  const swrFn = () => getApiStepUpdateSJQuestionsExtendedAll(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const postApiStepCreateStepType = (
    createStepTypeDto: CreateStepTypeDto,
 ) => {
      return customInstance<StepType>(
      {url: `/api/Step/CreateStepType`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createStepTypeDto
    },
      );
    }
  


export const getApiUser = (
    
 ) => {
      return customInstance<UserDto>(
      {url: `/api/User`, method: 'get'
    },
      );
    }
  

export const getGetApiUserKey = () => [`/api/User`];

    
export type GetApiUserQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUser>>>
export type GetApiUserQueryError = ErrorType<unknown>

export const useGetApiUser = <TError = ErrorType<unknown>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getApiUser>>, TError> & { swrKey?: Key, enabled?: boolean },  }

  ) => {

  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetApiUserKey() : null);
  const swrFn = () => getApiUser();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


