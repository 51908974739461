import { EventHandler, PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import {EmbedParams, EmbedReport} from "../../api/model";
import {ComponentProps, useState} from "react";
import * as embed from "embed";
import {pick} from "lodash";
import {Button} from "@mantine/core";
import {
    IconArrowAutofitContentFilled,
    IconArrowAutofitWidth,
    IconArrowsDiagonal,
    IconGlassFull,
    IconGrowth
} from "@tabler/icons-react";
import {IconArrowAutofitContent} from "@tabler/icons";

function PbiReportComponent({ pbi }: { pbi: EmbedParams }) {
    const [report, setReport] = useState<any>()
  if (pbi?.embedReport == undefined) return <div></div>;
  return (
      <>
      <Button onClick={()=>{report?.fullscreen()}} variant={"outline"} m={4}>
          <IconArrowsDiagonal /> 
      </Button>
    <PowerBIEmbed
      embedConfig={{
        type: "report", // Supported types: report, dashboard, tile, visual and qna
        //@ts-ignore
        id: pbi?.embedReport[0]?.reportId,
        //@ts-ignore
        embedUrl: pbi?.embedReport[0]?.embedUrl,
        accessToken: pbi?.embedToken?.token,
        tokenType: models.TokenType.Embed,
        settings: {
            
          panes: {
            filters: {
              expanded: false,
              visible: pbi?.showFilters,
            },
          },
          //background: models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={
        new Map<string, EventHandler>([
          [
            "loaded",
            function () {
              //console.log("Report loaded");
            },
          ],
          [
            "rendered",
            function () {
              //console.log("Report rendered");
            },
          ],
          [
            "error",
            function (event) {
              //console.log(event?.detail);
            },
          ],
        ])
      }
      cssClassName={"report-style-class"}
      getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport)
        //console.log("embeddedReport",embeddedReport)
        //@ts-ignore
        //this.report = embeddedReport;
      }}
    />
        
      </>
  );
}

export default PbiReportComponent;
