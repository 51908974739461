import { ReactNode, useEffect } from "react";
import TopBar from "./TopBar";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Container, Flex, Group, Navbar, ScrollArea } from "@mantine/core";
import styled from "@emotion/styled";

import { create, useStore } from "zustand";
import { useAuth } from "../auth/AuthContext";
import { preload } from "swr";
import {
  getApiCallGetCallList,
  getApiOrganizationGetOrganizations,
  getGetApiCallGetCallListKey,
  getGetApiOrganizationGetOrganizationsKey,
} from "../api/endpoints";
import OrganizationMenu from "./Organization";

const StyledContainer = styled(Container)`
  padding-top: 5rem;
  flex: 1 1 auto;
  overflow: auto;
  max-width: 90rem;
`;

const StyledFlex = styled(Flex)`
  background-color: #fafafa;
`;
type SidebarStoreState = {
  content: ReactNode | null;
  setContent: (content: ReactNode) => void;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
};

export const sidebarStore = create<SidebarStoreState>((set) => ({
  content: null,
  setContent: (content: ReactNode) => set({ content }),
  sidebarOpen: false,
  setSidebarOpen: (open: boolean) => set({ sidebarOpen: open }),
}));

function Layout() {
  const sidebarContent = useStore(sidebarStore, (state) => state.content);
  const setSidebarOpen = useStore(
    sidebarStore,
    (state) => state.setSidebarOpen
  );
  const isSidebarOpen = useStore(sidebarStore, (state) => state.sidebarOpen);
  const location = useLocation();
  const navigate = useNavigate();
  const auth  = useAuth();
  const isAuthenticated = !(!auth.isAuthenticated || !auth.access_token || auth.access_token == "" || auth.access_token == "null")  
  useEffect(() => {
    sidebarStore.setState({ content: null });
    if (location.pathname === "/" && isAuthenticated) {
      preload(getGetApiCallGetCallListKey(), getApiCallGetCallList);
      preload(
        getGetApiOrganizationGetOrganizationsKey(),
        getApiOrganizationGetOrganizations
      );
      navigate("/calls");
    }
  }, [location.pathname]);

  useEffect(() => {
    setSidebarOpen(isAuthenticated ?? false);
  }, [isAuthenticated]);
  return (
    <StyledFlex direction="column">
      {isAuthenticated && <TopBar />}
      <Flex mt={30} style={{ zIndex: 1, height: "100vh" }}>
        {isAuthenticated && isSidebarOpen && (
          <Navbar width={{ base: 300 }} pt={60} px={20} h={window.innerHeight}>
            <ScrollArea h={window.innerHeight}>
              <Group grow mx="-xs" px="xs" py={"xs"}>
                {sidebarContent ?? <OrganizationMenu />}
              </Group>
            </ScrollArea>
          </Navbar>
        )}
        <StyledContainer w={{ sm: 800, md: 1024, lg: 1080, xl: 1400 }} pb={100}>
          <Outlet />
        </StyledContainer>
      </Flex>
    </StyledFlex>
  );
}

export default Layout;
