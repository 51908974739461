import { Button, Group, Text, useMantineTheme } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import FilesList from "./FilesList";
import { postApiFileUploadUploadFile } from "../../api/endpoints";
import { PostApiFileuploadUploadfiletosubmissionBody } from "../../api/model";
import { useTranslation } from "react-i18next";

function FileUpload() {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  return (
    <>
      <Dropzone
        onDrop={(files) => {
          const formData = new FormData();
          formData.append("file", files[0]);
          const fileUploadReq: PostApiFileuploadUploadfiletosubmissionBody = {
            Nome: files[0].name,
            Descrizione: "",
            TipoFileId: "",
            File: formData as unknown as Blob,
          };
          postApiFileUploadUploadFile(fileUploadReq);
        }}
        onReject={(files) => console.log("rejected files", files)}
        maxSize={3 * 1024 ** 2}
        accept={[
          MIME_TYPES.png,
          MIME_TYPES.jpeg,
          MIME_TYPES.docx,
          MIME_TYPES.mp4,
          MIME_TYPES.xlsx,
        ]}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 220, pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === "dark" ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              {t("pages.admin.drag-images-select-files")}
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              {t("pages.admin.attach-many-files")}
            </Text>
          </div>
        </Group>
      </Dropzone>

      <Group position="right" mt="md">
        <Button type="submit">{t("pages.admin.submit")}</Button>
      </Group>

      <FilesList />
    </>
  );
}

export default FileUpload;
