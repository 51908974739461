import { useEffect, useState } from "react";
import { FillableComponentProps } from "../pages/calls/stepsConfig";
import UploadFile from "./UploadFile";
import {ExtraInfoAllegatoResult} from "../api/model";
import {mutate} from "swr";
import {
 getGetApiCallGetSubmissionByIDKey,
 useGetApiCallGetStepResult
} from "../api/endpoints";

export type FileContent = {
  file_name: string,
  file_description: string,
  file_type: string,
  dimensions: number,
  mandatory: false,
}

export const UploadList = ({
  submission,
  data,
  step,
  setData
}: FillableComponentProps) => {
  //console.log("upload content",step.content)
  //console.log("upload content data",data)
  //console.log("upload content step",step)
  //console.log("upload content submission",submission)
  const {
      data: currentStepResult,
      isLoading: srLoading,
      error: srErr,
    } = useGetApiCallGetStepResult(
      { submissionId: submission.id!, StepId: step.id },
      {
        swr: {
          shouldRetryOnError: false,
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        },
      }
    );
  

  const [stepFiles, setStepFiles ] = useState<FileContent[]>(step.content && step.content != ""  && JSON.parse(step.content!) ? JSON.parse(step.content!) : []);
  const [stepContentData, setStepContentData ] = useState<ExtraInfoAllegatoResult[] | undefined>();
  //  () => { 
  //   console.log("useState -> currentStepResult",currentStepResult,currentStepResult && currentStepResult.content ? JSON.parse(currentStepResult?.content!):[]);
  //   return currentStepResult && currentStepResult.content ? JSON.parse(currentStepResult?.content!):[];}
  // );//return currentStepResult && data != ""  && JSON.parse(data!) ? JSON.parse(data) : [];});
  const [first, setFirst] = useState(true);
  useEffect( () => {
    setStepContentData(currentStepResult && currentStepResult.content ? JSON.parse(currentStepResult?.content!):[]);
    // console.log("useEffect1 -> currentStepResult",currentStepResult)
    // console.log("useEffect1 -> stepContentData",stepContentData)
    // console.log("useEffect1 -> stepFiles",stepFiles)
  },[currentStepResult])
  useEffect( () => {
    // console.log("useEffect2 -> first",first)
    // console.log("useEffect2 -> currentStepResult",currentStepResult)
    // console.log("useEffect2 -> stepContentData",stepContentData)
    // console.log("useEffect2 -> stepFiles",stepFiles)
    
    if(!first){
      setData(JSON.stringify(stepContentData)).then((res)=>{
          // console.log('useEffect -> setData',res);
          const submissionId = res.callSubmissionId
          
      });
    }
    setFirst(currentStepResult===undefined);
  },[stepContentData])

  return (
    <>
      {stepContentData && stepFiles.map((e, i) => {
        return (
          <>
            <UploadFile 
                content={e} 
                key={i} 
                data={stepContentData[i]} 
                setData={async (value: ExtraInfoAllegatoResult)=>{
                  setStepContentData(stepFiles.map((file, idx) => {
                    if (idx === i) return value;
                    return stepContentData[idx]
                  }));
                }}
             />
          </>
        )
      })}
    </>
  )
}
