import SelectIndicator from "./SelectIndicator";
import SelectOutcome from "./SelectOutcome";
import SelectSDG from "./SelectSDG";
import useMiniDyiStore, { miniDyiStoreState } from "./miniDYIStore";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import SelectBudget from "./SelectBudget";
import {
  getGetApiCallGetStepResultKey,
  putApiImpactFrameworkPutMiniDYI,
  useGetApiCallGetStepResult,
} from "../../api/endpoints";
import { useParams } from "react-router-dom";
import { MiniDyiReqDTO, Step, StepResult } from "../../api/model";
import { useStepperStore } from "../CallStepper";
import { mutate } from "swr";
import MiniDyiIntro from "./Intro";
import CustomOutComeStep from "./CustomOutComeStep";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { createContext } from 'react';
import {Center, Loader} from "@mantine/core";

export const ImpactFrameworkIdContext = createContext('');

const MiniDYI = forwardRef(
  (
    {
      step,
      setData,
      impactFrameworkId,
      readOnly
    }: {
      step: Step;
      setData: (value: string) => Promise<StepResult>;
      impactFrameworkId: string | undefined;
      readOnly?: boolean
    },
    ref: ForwardedRef<any> | undefined
  ) => {
    const { t } = useTranslation();
    const { submissionId } = useParams();
    const currentSubStep = useStepperStore((state) => state.currentStep[1]);
    const cleanup = useMiniDyiStore((state) => state.cleanup);
    const hydrate = useMiniDyiStore((state) => state.hydrate);
    const state = useMiniDyiStore((state) => state);

    useImperativeHandle(ref, () => ({
      validate() {
        //console.log("validate called");
        if (
          (currentSubStep === 4 || currentSubStep === 5) &&
          state.selectedOutcomes.length !==
            state.selectedOutcomes
              .map((so) => so.stakeholderId)
              .filter((sid) => typeof sid != "undefined" && sid !== "").length
        ) {
          showNotification({
            message: t("components.mini-dyi.error-stakeholders"),
            color: "red",
          });
          return false;
        }
        //console.log(state);
        return true;
      },
    }));
    const putMiniDYI = async (
      stepResultID: string,
      state: Omit<MiniDyiReqDTO, "stepResultID">
    ) => {
      //console.log({
      //  budget: state.budget,
      //  budgetSpent: state.budgetSpent,
      //  stepResultID,
      //});
      await putApiImpactFrameworkPutMiniDYI({
        outcomes: state.outcomes,
        budget: state.budget,
        budgetSpent: state.budgetSpent,
        stepResultID,
      });
    };
    const {
      data: currentStepResult,
      isLoading: srLoading,
      error: srErr,
    } = useGetApiCallGetStepResult(
      { submissionId: submissionId!, StepId: step.id },
      {
        swr: {
          shouldRetryOnError: false,
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        },
      }
    );
    const saver = (state: miniDyiStoreState) =>
      setData(
        JSON.stringify({
          selectedOutcomes: state.selectedOutcomes,
          budget: state.budget,
          budgetSpent: state.budgetSpent,
          sdg: state.sdg,
          stepId: state.stepId,
        })
      )
        .then((sr) => {
          mutate(
            getGetApiCallGetStepResultKey({
              submissionId: submissionId!,
              StepId: step.id,
            })
          );
          return sr.id;
        })
        .then((srId) =>
          putMiniDYI(srId as string, {
            outcomes: state.selectedOutcomes.filter(
              (so) => !!so.outcomeId || !!so.outcomeName
            ),
            budget: state.budget,
            budgetSpent: state.budgetSpent,
          })
        )
        .then(() => console.log("mini dyi saved"));
    const saverCb = useCallback(saver, []);
    const hydrateCb = useCallback(hydrate, []);

    useEffect(() => {
      const unsub = useMiniDyiStore.subscribe(saverCb);
      return () => {
        unsub();
        cleanup();
      };
    }, []);
    useEffect(() => {
      if (!srLoading && !srErr && currentStepResult?.stepId == step.id) {
        hydrateCb(currentStepResult?.content!);
      }
    }, [hydrateCb, srLoading, srErr, step]);

    if (!impactFrameworkId){
        return <Center><Loader></Loader></Center>
    }
    return (
      <ImpactFrameworkIdContext.Provider value={impactFrameworkId!.replace(/['"]+/g, '')}>
        {currentSubStep === 0 && <MiniDyiIntro></MiniDyiIntro>}
        {currentSubStep === 1 && <SelectBudget readOnly={readOnly}></SelectBudget>}
        {currentSubStep === 2 && <SelectSDG readOnly={readOnly}></SelectSDG>}
        {currentSubStep === 3 && <SelectOutcome readOnly={readOnly}></SelectOutcome>}
        {currentSubStep === 4 && <SelectIndicator readOnly={readOnly}></SelectIndicator>}
        {currentSubStep === 5 && <CustomOutComeStep readOnly={readOnly}></CustomOutComeStep>}
      </ImpactFrameworkIdContext.Provider>
    );
  }
);

export default MiniDYI;
