import {Button, Center, Group, Loader, Tabs} from "@mantine/core";
import { t } from "i18next";
import { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { create, useStore } from "zustand";
import {
  postApiCallCreateCall,
  postApiCallCreateCallType, useGetApiPBITokens,
} from "../../api/endpoints";

import addMonths from "../../utils/AddMonths";
import CustomizeList from "./sections/CustomizeList";
import {useConfig} from "../../hooks/useConfig";
import {useAuth} from "../../auth/AuthContext";
//import PbiReportComponent from "../powerBi/PbiReportComponent";
const PbiReportComponent = lazy(() => import("../powerBi/PbiReportComponent"));

const SubmissionsList = lazy(() => import("./sections/SubmissionsList"));
const CallsList = lazy(() => import("./sections/CallsList"));
const FrameworkList = lazy(() => import("./sections/FrameworkList"));

type ShowClosedState = {
  show: boolean;
  setShow: (show: boolean) => void;
  label: string;
  setLabel: (label: string) => void;
};
export const showCloseStore = create<ShowClosedState>((set) => ({
  show: false,
  setShow: (show: boolean) => set({ show }),
  label: t("pages.admin.show-closed"),
  setLabel: (label: string) => set({ label }),
}));

function toggleShowCloseLabel(showClosed: boolean): string {
  if (showClosed) return t("pages.admin.hide-closed");
  return t("pages.admin.show-closed");
}
function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useConfig();
  const {user} = useAuth()
  const [activeTab, setActiveTab] = useState<string | null>(
    location.state ?? "calls"
  );
  // console.log("ASD: ", location);
  const { t } = useTranslation();
  const showCloseLabel = useStore(showCloseStore, (state) => state.label);
  const showClosed = useStore(showCloseStore, (state) => state.show);
  const setShowClosed = useStore(showCloseStore, (state) => state.setShow);
  const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

  

  useEffect(() => {
    showCloseStore.setState({ label: toggleShowCloseLabel(showClosed) });
  }, [showClosed]);
  const {
    data: pbi,
    error,
    isLoading,
  } = useGetApiPBITokens(
    config.workspacePowerBiId && config.reportPowerBiId
      ? {
          workspaceId: config.workspacePowerBiId,
          reportId: config.reportPowerBiId,
        }
      : { workspaceId: EMPTY_GUID, reportId: EMPTY_GUID } // or handle this case differently
  );
  return (
    <Tabs value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="calls">{t("pages.admin.calls")}</Tabs.Tab>
        <Tabs.Tab value="submissions">{t("pages.admin.submissions")}</Tabs.Tab>
        <Tabs.Tab value="report">{t("pages.admin.report")}</Tabs.Tab>

        <Tabs.Tab value="customize">{t("pages.admin.configuration")}</Tabs.Tab>
        <Tabs.Tab value="impactframe">
          {t("pages.admin.impact-framework")}
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="calls">
        <Group my="lg">
          <Button
            variant="filled"
            color="blue"
            radius="sm"
            style={{ float: "right" }}
            onClick={async () => {
              const callType = await postApiCallCreateCallType({
                name: "",
                description: "",
                steps: [],
              });
              const call = await postApiCallCreateCall({
                name: "",
                description: "",
                openingAt: addMonths(new Date(), 1).toISOString(),
                closingAt: addMonths(new Date(), 3).toISOString(),
                callTypeId: callType.id,
              });
              navigate(`/admin/calls/${call.id}`);
            }}
          >
            {t("pages.admin.create-call")}
          </Button>

          <Button
            variant="filled"
            color="blue"
            radius="sm"
            style={{ float: "right" }}
            onClick={() => {
              setShowClosed(!showClosed);
            }}
          >
            {showCloseLabel}
          </Button>
        </Group>
        <Suspense fallback={<Loader></Loader>}>
          <CallsList showClosed={showClosed}></CallsList>
        </Suspense>
      </Tabs.Panel>

      <Tabs.Panel value="submissions">
        <Suspense fallback={<Loader></Loader>}>
          <SubmissionsList></SubmissionsList>
        </Suspense>
      </Tabs.Panel>

      {user?.email?.includes("openimpact.it") ? (
          
      <Tabs.Panel value="customize">
        <Group my="lg">
          <Button
            variant="filled"
            color="blue"
            radius="sm"
            style={{ float: "right" }}
            onClick={() => navigate(`/admin/customize`)}
          >
            {t("pages.admin.create-customize")}
          </Button>
        </Group>
        
        <Suspense fallback={<Loader></Loader>}>
          <CustomizeList></CustomizeList>
        </Suspense>
      </Tabs.Panel>
      ):null}
      {config.reportPowerBiId && config.workspacePowerBiId && pbi ? 
          <Tabs.Panel value="report">
            <Suspense fallback={<Loader></Loader>}>
              <PbiReportComponent pbi={pbi}></PbiReportComponent>
            </Suspense>
          </Tabs.Panel>
      : null}
      <Tabs.Panel value="impactframe">
        <Group my="lg">
          <Button
            variant="filled"
            color="blue"
            radius="sm"
            style={{ float: "right" }}
            onClick={() => {
              navigate("/admin/impact-framework");
            }}
          >
            {t("pages.admin.new-framework")}
          </Button>
        </Group>
        <Suspense fallback={<Loader></Loader>}>
          <FrameworkList showClosed={showClosed} />
        </Suspense>
      </Tabs.Panel>
    </Tabs>
  );
}

export default Dashboard;
