import { useParams } from "react-router-dom";
import { Flex, Loader } from "@mantine/core";
import {
  getGetApiCallGetSubmissionByIDKey,
  useGetApiCallGetSubmissionByID,
  useGetApiOrganizationGetOrganizations,
} from "../../api/endpoints/index";
import CallStepper from "../../components/CallStepper";
import useSelectedOrganizationId from "../../hooks/useSelectedOrganizationId";
import { useEffect } from "react";
import { mutate } from "swr";

const Call = () => {
  const { submissionId , readonly} = useParams();
  const [selectedOrganizationId, setSelectedOrganizationId] = useSelectedOrganizationId();
  // const [selectedOrgId, setSelectedOrgId] = useSelectedOrganizationId();
  const { data: submission, error: submissionError } =
    useGetApiCallGetSubmissionByID(
      {
        submissionId: submissionId!,
      },
      {
        swr: {
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        },
      }
    );
  
  // const { data: orgs } = useGetApiOrganizationGetOrganizations();
  // useEffect(() => {
  //   if (submissionError || (!selectedOrganizationId && orgs?.length)) {
  //     const firstOrg =
  //       Array.isArray(orgs) && orgs.length && typeof orgs[0] !== "undefined"
  //         ? orgs[0]
  //         : undefined;
  //     if (firstOrg) {
  //       setSelectedOrganizationId(firstOrg.id!);
  //       mutate(
  //         getGetApiCallGetSubmissionByIDKey({ submissionId: submissionId! })
  //       );
  //     }
  //   }
  // }, [
  //   selectedOrganizationId,
  //   orgs,
  //   setSelectedOrganizationId,
  //   submissionError,
  //   submission,
  // ]);

  if (submissionError) {
    return <Loader></Loader>;
  }
  if (submission === undefined) {
    return <Loader></Loader>;
  }
  return (
    <Flex>
      <CallStepper.Sidebar></CallStepper.Sidebar>
      <CallStepper.Stepper
        submission={submission}
        callTypeId={submission.callCallTypeId!}
        results={submission.stepResults!}
        readonly={!!readonly}
      ></CallStepper.Stepper>
    </Flex>
  );
};

export default Call;
